import { ClientContextCustomer } from '@ancon/wildcat-types'

import isValidPhoneNumber from '../../app/utils/isValidPhoneNumber'

/**
 * This function checks if the customer has incomplete details.
 *
 * Any customer with missing following details is considered incomplete:
 * - Email
 * - First name
 * - Last name
 * - Phone number
 */
export default function hasIncompleteCustomerDetails(
  customer: ClientContextCustomer | null,
): boolean {
  return (
    !customer?.email ||
    !customer.firstName?.trim() ||
    !customer.lastName?.trim() ||
    !isValidPhoneNumber(customer.telephoneNumber || '')
  )
}
